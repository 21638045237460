<div>
    <template v-if="this.scenarioData === null">
        <Loader class="mt-4" />
    </template>

    <template v-else>
        <page-header>
            <div class="row c">
                <Breadcrumbs
                    :items="[
                        {title:'Сценарии', onClick: () => {$router.push({name: 'ScenariosList'})}},
                        {title: this.scenarioData.scenarioGroup.name},
                    ]"
                />
            </div>
        </page-header>

        <PageTitle :title="this.scenarioData.name" />

        <div class="row c mt-4">
            <div class="row">
                <ScenarioConditions
                    :conditions="scenarioConditions"
                    @action="actionHandler"
                />
            </div>
        </div>

        <div class="row c mt-2">
            <div class="row">
                <ScenarioProductTree
                    :scenario-data="scenarioData"
                    @action="actionHandler"
                />
            </div>
        </div>
        <div class="row c mt-2">
            <ExpansionItem
                v-model="showSelectionBlock"
                label="Правила рейтингования товаров"
                class="mb-1"
                header-class="scenario__expansion-block"
            >
                <div class="row mt-3 pl-2">
                    <FormCheckbox
                        label="Опубликованные"
                        v-model="questionsFilter.published"
                        wrapper-class="inline"
                    />
                    <FormCheckbox
                        label="С подбором по ТТХ"
                        v-model="questionsFilter.selection"
                        wrapper-class="inline"
                    />
                    <FormCheckbox
                        label="С предусловием"
                        v-model="questionsFilter.answerSelection"
                        wrapper-class="inline"
                    />
                    <FormCheckbox
                        label="Удаленные"
                        v-model="questionsFilter.showRemoved"
                        wrapper-class="inline"
                    />
                </div>
                <div class="row mt-3 pl-2">
                    <div v-for="rules in filteredQuestions" class="mb-1">
                        <expansion-item :label="rules.details.name" header-class="bg-light-green">
                            <template #label-right>
                                <edit-question-actions
                                    :id="parseInt(rules.id)"
                                    type="QUESTION"
                                    @action="actionHandler"
                                    :remove-question="parseInt(rules.removed, 10) === 0"
                                    :restore-question="parseInt(rules.removed, 10) === 1"
                                />
                            </template>
                            <grid :columns="rulesGridColumns" :data="[]" class="w100"></grid>
                            <div class="mb-1">
                                <div v-for="rule in rules.answers" class="scenario__rule-line">
                                    <grid
                                        :columns="rulesGridColumns"
                                        hide-header
                                        :data="[rule]"
                                        class="w100"
                                    >
                                        <template #name="{ data: item }">
                                            <span
                                                @click="toggleRules(item.id)"
                                                :class="{
                                                    'pointer': 'pointer',
                                                    'scenario__rules': 'scenario__rules',
                                                    'text-decoration-line-through': parseInt(item.removed, 10) === 1
                                                }"
                                            >
                                                <span v-if="visibleRules.includes(item.id)" class="icon bg-60 arrow"></span>
                                                <span v-else class="icon bg-60 arrow"  style="transform: rotate(-90deg);"></span>
                                                {{ item.details.name }}
                                            </span>
                                        </template>
                                        <template #precondition="{ data: item }">
                                            {{checkProperty('answerSelectionConditions', item)}}
                                        </template>
                                        <template #selection="{ data: item }">
                                            {{checkProperty('productSelectionConditions', item)}}
                                        </template>
                                        <template #published="{ data: item }">
                                            {{item.details.published ? '+' : '-'}}
                                        </template>
                                        <template #actions="{ data: question }">
                                            <edit-question-actions
                                                :id="parseInt(question.id)"
                                                type="ANSWER"
                                                class="scenario__action-buttons"
                                                @action="actionHandler"
                                                :remove-question="parseInt(question.removed, 10) === 0"
                                                :restore-question="parseInt(question.removed, 10) === 1"
                                            />
                                        </template>
                                    </grid>
                                    <div
                                        class="scenario__selection-rules border mb-2"
                                        :class="{show: visibleRules.includes(rule.id), hide:!visibleRules.includes(rule.id)}"
                                    >
                                        <tabs
                                            class="bg-white"
                                            :columns="rulesRulesTypeTabs"
                                            selected="productSelectionConditions"
                                            :data="{
                                                answerSelectionConditions: rule.answerSelectionConditions,
                                                productSelectionConditions: rule.productSelectionConditions,
                                            }"
                                        >
                                            <template #answerSelectionConditions="{ data: conditions }">
                                                <ConditionViewer
                                                    :items="conditions || []"
                                                    :shown-elements="[]"
                                                />
                                            </template>
                                            <template #productSelectionConditions="{ data: conditions }">
                                                <ConditionViewer
                                                    :items="conditions || []"
                                                    :shown-elements="[]"
                                                />
                                            </template>
                                        </tabs>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-1">
                                <add-line
                                    @click="editScenarioQuestion(0, {type: 'ANSWER', parentId: rules.id})"
                                />
                            </div>
                        </expansion-item>
                    </div>
                </div>
                <div class="row mt-3">
                    <FormButton
                        label="Добавить группу"
                        @click="editScenarioQuestion()"
                        :attributes="{
                            'class': ['btn-blue'],
                        }"
                    />
                </div>
            </ExpansionItem>

        </div>

        <div class="row c mt-2">
            <ExpansionItem
                label="Выгоды"
                class="mb-1"
                header-class="scenario__expansion-block"
            >
                <ScenarioBenefits
                    v-if="benefitsTreeData.length"
                    v-model:benefits-tree-data="benefitsTreeData"
                    :loading="benefitsLoading"
                    ref="scenarioBenefits"
                    @action="actionHandler"
                />
            </ExpansionItem>
        </div>
    </template>

    <teleport to="body" v-if="showConfirmModal">
        <confirm
                v-model:show-modal="showConfirmModal"
                :title="confirmBts.title"
                :buttons="confirmBts.buttons"
        />
    </teleport>

    <teleport to="body" v-if="showEditScenarioProductsModal">
        <modal
            @onOk="closeEditScenarioModal"
            @onClose="closeEditScenarioModal"
        >
            <EditScenarioProducts
                ref="editScenarioProducts"
                :data="editScenarioData"
                :on-save="saveScenario"
                :on-cancel="closeEditScenarioModal"
            />
        </modal>
    </teleport>

    <teleport to="body" v-if="showEditQuestionModal">
        <modal
            @onOk="closeEditQuestionModal"
            @onClose="closeEditQuestionModal"
        >
            <EditQuestion
                ref="editScenarioQuestion"
                v-model:question="selectedQuestionDataEdit"
                :on-save="saveScenarioQuestion"
                :on-cancel="closeEditQuestionModal"
            />
        </modal>
    </teleport>

    <teleport to="body" v-if="showEditBenefitConditionsModal">
        <modal
            @onOk="closeEditBenefitConditionsModal"
            @onClose="closeEditBenefitConditionsModal"
        >
            <EditBenefitConditions
                ref="editBenefitConditions"
                v-model:conditions="benefitConditionsEdited"
                :condition-property-source="`PARTNER`"
                :benefit-name="benefitEdited.title"
                :on-save="saveBenefitConditions"
                :on-cancel="closeEditBenefitConditionsModal"
            />
        </modal>
    </teleport>

    <teleport to="body" v-if="showEditScenarioConditionsModal">
        <modal
            @onOk="closeEditScenarioConditionsModal"
            @onClose="closeEditScenarioConditionsModal"
        >
            <EditScenarioConditions
                ref="editScenarioConditions"
                :conditions-data="scenarioConditionsEditData"
                :on-save="saveScenarioConditions"
                :on-cancel="closeEditScenarioConditionsModal"
            />
        </modal>
    </teleport>
</div>
