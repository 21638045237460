<div class="tree">
    <ul class="tree__node-group first">
            <ConditionPool
                v-for="(editorItem, editorItemIndex) in items"
                :item="items[editorItemIndex]"
                :item-index="editorItemIndex"
            >
                <template v-slot:actions="itemData">
                    <slot name="actions" :data="itemData.data"></slot>
                </template>
                <template v-slot:extraConditionData="itemData">
                    <slot name="extraConditionData" :data="itemData.data"></slot>
                </template>
                <template v-slot:extraPoolData="poolData">
                    <slot name="extraPoolData" :data="poolData.data"></slot>
                </template>
                <template v-slot:propertyTooltipData="conditionData">
                    <slot name="propertyTooltipData" :data="conditionData.data"></slot>
                </template>
            </ConditionPool>
    </ul>
</div>
