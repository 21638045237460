<template src="../templates/edit-answer-conditions.html"></template>
<script>

import ConditionEditor from '@c/ConditionEditor';
import ExpansionItem from '@c/ExpansionItem';
import FormButton from '@f/Button';
import helpers from '@/tools/helpers';

export default {
    name: 'EditAnswerConditions',
    components: {
        ConditionEditor,
        ExpansionItem,
        FormButton,
    },
    props: {
        conditions: {
            type: Array,
            default() {
                return [];
            },
        },
        label: {
            type: String,
            default() {
                return '';
            }
        },
        conditionPropertySource: {
            type: String,
            default() {
                return '';
            }
        },
    },
    emits: ['update:conditions', 'remove'],
    data() {
        return {
            conditionsEdited: [...helpers.deepCopy(this.conditions)],
            blockExpanded: false,
        };

    },
    watch: {
        conditionsEdited: {
            handler() {
                this.$emit('update:conditions', this.conditionsEdited);
            },
            deep: true,
        },
    },
    methods: {
        removeAction() {
            this.$emit('remove');
        }
    },
};
</script>
