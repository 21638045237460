<template src="../templates/edit-scenario-products.html" />

<script>
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import FormCheckbox from '@f/Check';
import Multiselect from '@vueform/multiselect';
import helpers from '@/tools/helpers';
import {SCENARIO_DEFAULT_VALUES} from '@page/Scenario/config';
import TreeSelectDialog from '@c/TreeSelectDialog';
import Grid from '@c/Grid';

import {getScenariosTree} from '@api/scenarios';
import {getProductCategories, getProductMachines, getManufacturers} from '@api/product';
import {watchModifyMixin} from '@/mixins/watchModifyMixin';

export default {
    name: 'EditScenarioProducts',
    components: {
        FormInput,
        FormButton,
        FormSelect,
        Multiselect,
        Grid,
        FormCheckbox,
        TreeSelectDialog,
    },
    mixins: [
        watchModifyMixin('editData', 'editDataInitial'),
    ],
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },
        onSave: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        onCancel: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    data() {
        return {
            editData: {
                ...helpers.deepCopy(SCENARIO_DEFAULT_VALUES),
                ...this.data,
            },
            editDataInitial: {
                ...helpers.deepCopy(SCENARIO_DEFAULT_VALUES),
                ...helpers.deepCopy(this.data),
            },
            loading: false,
            productGridColumns: Object.freeze([
                {
                    slot_name: 'number',
                    name: 'number',
                    label: '№',
                    width: 'minmax(50px, 0.2fr)',
                },
                {
                    name: 'name',
                    label: 'Товар',
                    width: 'minmax(250px, 1.2fr)',
                },
                {
                    slot_name: 'manufacturer',
                    name: 'manufacturer',
                    label: 'Производитель',
                    width: 'minmax(100px, 0.5fr)',
                },
                {
                    slot_name: 'selected',
                    name: 'selected',
                    label: 'Вкл. в подбор',
                    width: 'minmax(50px, 0.2fr)',
                },
                {
                    slot_name: 'is_option',
                    name: 'is_option',
                    label: 'Опция',
                    width: 'minmax(50px, 0.2fr)',
                },
            ]),
            productCategories: [],
            products: [],
            manufacturers: [],
            productFilter: '',
            manufacturerFilter: '',
            showOnlySelectedFilter: 0,
            productCategoriesFilter: [],
            selectedProducts: {},
            scenariosGroupTree: [], // Все группы сценариев, в т.ч. помеченные на удаление
            showGroupSelectDialog: false,
        };
    },
    computed: {
        validated() {
            return this.editData.name.length > 2;
        },
        filteredProducts() {
            let filteredProducts = this.products;

            // Фильтр по названию товара
            if (this.productFilter) {
                filteredProducts = filteredProducts.filter(
                    product => product.name.toLowerCase().includes(this.productFilter.toLowerCase())
                );
            }

            // Фильтр по производителю
            if (this.manufacturerFilter) {
                const filteredManufacturers = this.manufacturers.filter(
                    manufacturer => {
                        let result = false;
                        if (manufacturer.short_name) {
                            result = manufacturer.short_name.toLowerCase().includes(this.manufacturerFilter.toLowerCase());
                        }
                        if (!result && manufacturer.full_name) {
                            result = manufacturer.full_name.toLowerCase().includes(this.manufacturerFilter.toLowerCase());
                        }
                        return result;
                    }
                );
                filteredProducts = filteredProducts.filter(
                    product => filteredManufacturers.map(
                        manufacturer => manufacturer.id
                    ).includes(product.manufacturer_id)
                );
            }

            // Фильтр по группе товаров
            if (this.productCategoriesFilter.length) {
                filteredProducts = filteredProducts.filter(
                    product => product.categories.map(c => c.id)
                        .some(category => this.productCategoriesFilter.map(c => c.id).includes(category))
                );
            }

            // Показать только включенные в подбор
            if (this.showOnlySelectedFilter) {
                filteredProducts = filteredProducts.filter(
                    product => this.editData.productMachines.find(selectedProduct => product.id === selectedProduct.id)
                );
            }

            // Сортировка по названию товара
            filteredProducts.sort((a, b) => a.name.localeCompare(b.name));

            return filteredProducts;
        },
        published: {
            get() {
                return this.editData.published === '1';
            },
            set(value) {
                this.editData.published = value ? '1' : '0';
            }
        },
        groupLabel() {
            const findNode = (nodes, id) => {
                let result = null;
                for (let i = 0; i < nodes.length; i++) {
                    if (nodes[i].id === id) {
                        result = nodes[i];
                        break;
                    } else {
                        result = findNode(nodes[i].children, id);
                        if (result) {
                            break;
                        }
                    }
                }
                return result;
            };

            const node = findNode(this.scenariosGroupTree, this.editData.scenario_group_id);
            if (node) {
                return node.name;
            }
            return '';
        },
        activeScenariosGroupTree() {
            // Только не помеченные на удаление группы сценариев
            const getNotRemovedNodes = nodes => {
                const filteredNodes = [];
                nodes.filter(node => node.removed === '0').forEach(node => {
                    filteredNodes.push({...node, children: getNotRemovedNodes(node.children)});
                });
                return filteredNodes;
            };

            return getNotRemovedNodes(this.scenariosGroupTree);
        },
        filteredProductsAllSelected() {
            const filteredProductIds = Object.values(this.filteredProducts).map(v => v.id);
            const selectedProductIds = Object.keys(this.selectedProducts);

            return filteredProductIds.every(v => selectedProductIds.includes(v));
        }
    },
    watch: {
        'editData.productMachineCategories': {
            handler() {
                const categoryIds = this.editData.productMachineCategories.map(category => category.id);
                if (categoryIds.length) {
                    getProductMachines(
                        {
                            product_machine_category_id: categoryIds,
                            hot: 1,
                        },
                        response => {
                            this.products = response;
                        }
                    );
                } else {
                    this.products = [];
                }
            },
            deep: true,
            immediate: true,
        },
        products() {
            if (!this.products.length) {
                this.manufacturers = [];
            } else {
                getManufacturers(
                    {id: this.products.map(product => product.manufacturer_id)},
                    response => {
                        this.manufacturers = response;
                    }
                );
            }
        },
    },
    mounted() {
        this.loading = true;

        this.editData.productMachines.forEach(product => {
            this.selectedProducts[product.id] = 1;
        });

        getProductCategories({
            excludeRootCategories: 1,
            excludeCategoriesWithoutHot: 1,
        })
            .then(data => {
                this.productCategories = data;
            })
            .finally(() => {
                this.loading = false;
            });

        getScenariosTree(
            {withoutScenarios: 1},
            response => {
                this.scenariosGroupTree = response;
            }
        );
    },
    methods: {
        save() {
            this.onSave(this.editData);
        },
        getManufacturerName(id) {
            const foundedManufacturer = this.manufacturers.find(manufacturer => manufacturer.id === id);
            if (foundedManufacturer) {
                return foundedManufacturer.short_name ? foundedManufacturer.short_name : foundedManufacturer.full_name;
            }
            return '';
        },
        onSelectProducts(productIds, select = true) {
            productIds.forEach(productId => {
                if (!select) {
                    this.editData.productMachines = this.editData.productMachines
                        .filter(product => product.id !== productId);
                    delete this.selectedProducts[productId];
                } else {
                    const productExist = this.editData.productMachines
                        .find(product => product.id === productId) !== undefined;

                    if (!productExist) {
                        this.editData.productMachines
                            .push(this.products.find(product => product.id === productId));
                        this.selectedProducts[productId] = 1;
                    }
                }
            });
        },
        toggleSelectedAll() {
            const filteredProductIds = Object.values(this.filteredProducts).map(v => v.id);

            if (this.filteredProductsAllSelected) {
                // удалить из выделенных все filteredProductIds
                this.onSelectProducts(filteredProductIds, false);
            } else {
                // добавить в выделенные все filteredProductIds
                this.onSelectProducts(filteredProductIds, true);
            }
        },
    },
};
</script>

<style lang="scss" src="../styles/edit-scenario-products.scss" />
