<label class="toggle-switch d-inline-flex">
    <slot name="leftLabel">
        <span v-if="leftLabel" class="mr-1">{{ leftLabel }}</span>
    </slot>

    <input
        v-model="checked"
        type="checkbox"
        class="toggle-switch__input"
        :disabled="disabled"
    />
    <span
        :style="trackStyle"
        :class="{
            [activeTrackClass]: checked,
            'bg-dark-gray': !checked,
        }"
        class="toggle-switch__track cursor-pointer"
    >
        <span class="toggle-switch__indicator bg-white" :style="indicatorStyle"></span>
    </span>

    <slot name="label">
        <span v-if="label" class="ml-1">{{ label }}</span>
    </slot>
</label>
