<template src="../templates/scenario-conditions-actions.html" />

<script>
import FormButton from '@f/Button';

export default {
    name: 'ScenarioConditionsActions',
    components: {
        FormButton,
    },
    props: {
        editScenarioConditions: {
            type: Boolean,
            default: true,
        }
    },
    emits: ['action'],
    methods: {
        call(action, id = null) {
            this.$emit('action', {action, id});
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/scenario-conditions';
</style>
