<template src="../templates/condition-value.html"></template>
<script>

import {boolOptions, getValueTemplateView} from '@c/ConditionEditor/config';

export default {
    name: 'ConditionValue',
    components: {
    },
    props: {
        valuesData: {
            type: [Array, String, Number, Boolean],
            default() {
                return [];
            },
        },
        options: {
            type: Array,
            default() {
                return null;
            },
        },
        type: {
            type: String,
            default() {
                return 'INT';
            },
        },
        operation: {
            type: String,
            default() {
                return 'EQUAL';
            },
        },
    },

    data() {
        return {
            values: Array.isArray(this.valuesData) ? [...this.valuesData] : [this.valuesData],
            boolOptions: boolOptions(),
        };
    },
    computed: {
        controlView() {
            return getValueTemplateView(this.options, this.operation, this.type);
        },
    },
};

</script>
