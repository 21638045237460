<template src="../templates/scenario-benefits-tree-actions.html" />

<script>
import FormButton from '@f/Button';

export default {
    name: 'ScenarioBenefitsTreeActions',
    components: {
        FormButton,
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        editBenefit: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['action'],
    methods: {
        call(action, id = null) {
            this.$emit('action', {action, id});
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../styles/scenario-benefits';
</style>
