<teleport to="body">
    <Modal
        v-if="show"
        @onClose="closeDialog"
    >
        <template #body>
            <div class="modal-section">
                <div class="row-col">
                    <div class="col-12">
                        <FormInput v-model="filter" label="Поиск" />
                    </div>
                </div>
                <div class="row-col mt-2">
                    <div class="col-12">
                        <tree
                            v-model="selectedValue"
                            :nodes="nodes"
                            :node-key="nodeKey"
                            :label-key="labelKey"
                            :children-key="childrenKey"
                            :filter="filter"
                        >
                            <template v-slot:label="row">
                                <slot name="name" :data="row.data"></slot>
                            </template>
                        </tree>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</teleport>
