<template>
    <div class="dropdown-item py-1 px-2">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'DropdownItem',
};
</script>

<style scoped lang="scss" src="../styles/dropdown.scss"></style>
