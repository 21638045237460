<template src="../templates/edit-benefit-conditions.html"></template>
<script>

import {watchModifyMixin} from '@/mixins/watchModifyMixin';
import ConditionEditor from '@c/ConditionEditor';
import Modal from '@c/Modal';
import FormButton from '@f/Button';
import helpers from '@/tools/helpers';

export default {
    name: 'EditBenefitConditions',
    components: {
        ConditionEditor,
        FormButton,
        Modal,
    },
    mixins: [
        watchModifyMixin('conditionsEdited', 'conditionsInitial'),
    ],
    props: {
        conditions: {
            type: Array,
            default() {
                return [];
            },
        },
        benefitName: {
            type: String,
            default() {
                return '';
            },
        },
        conditionPropertySource: {
            type: String,
            required: true,
            default() {
                return '';
            }
        },
        onSave: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        onCancel: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    emits: ['update:conditions', 'remove'],
    data() {
        return {
            conditionsEdited: [...helpers.deepCopy(this.conditions)],
            conditionsInitial: [...helpers.deepCopy(this.conditions)],
        };
    },
    watch: {
        conditionsEdited: {
            handler(val) {
                this.$emit('update:conditions', val);
            },
            deep: true,
        },
    },
    methods: {
        removeAction() {
            this.$emit('remove');
        },
    },
};
</script>
