<ExpansionItem
    v-model="blockExpanded"
    :label="label"
    class="mb-1"
    header-class="scenario__expansion-block"
>
    <div class="form-row my-2">
        <div class="col">
            <ConditionEditor
                v-model:items="conditionsEdited"
                :conditionPropertySource="conditionPropertySource"
            />
        </div>
    </div>
    <template v-slot:label-right>
        <FormButton
            type="a"
            class="btn btn-sm btn-red m-auto text-center"
            @click.prevent="removeAction"
        >
            <template v-slot:center>
                <span class="icon delete red m-auto"></span>
            </template>
        </FormButton>
    </template>
</ExpansionItem>
