<div class="row-col">
    <div class="col-9">
        <FormInput
            v-model="filter.title"
        />
    </div>
    <div class="col-3">
        <FormCheckbox
            v-model="filter.showHiddenBenefits"
            class="mt-1"
            label="Показать скрытые выгоды"
        />
    </div>
</div>
