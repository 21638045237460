<li class="tree__node-item condition">
    <div
        class="tree__level row"
    >
        <div class="cell">
            <tippy
                v-if="this.$slots.propertyTooltipData"
                allowHTML
                arrow
                arrowType="round"
                theme="light"
                placement="top"
            >
                <template #content>
                    <slot name="propertyTooltipData" :data="condition"></slot>
                </template>

                <ConditionProperty
                    :property="condition.property"
                />
            </tippy>
            <ConditionProperty
                v-else
                :property="condition.property"
            />
        </div>
        <div class="cell">
            <span class="condition__operator">
                {{catalogs.comparison_operators.find(v => v.value === condition.condition_type)?.title}}
            </span>
        </div>
        <div class="cell" v-if="typeof condition != 'undefined'">
            <ConditionValue
                :values-data="condition.value"
                :type="condition.value_type"
                :options="condition.property.options || null"
                :operation="condition.condition_type"
            />
        </div>
        <div class="cell" v-if="show.includes('points')">
            {{condition.points}}
        </div>
        <div class="cell">
            <slot></slot>
        </div>
    </div>
    <span><slot name="addSlot"></slot></span>

</li>
