<div class="scenario-conditions-actions">
    <form-button
        v-if="editScenarioConditions"
        type="a"
        class="btn btn-sm btn-blue text-center m-1"
        @click="call('editScenarioConditions')"
    >
        <template #center>
            <span class="icon edit green m-auto"></span>
        </template>
    </form-button>
</div>
