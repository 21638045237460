<template src="../templates/edit-question.html"></template>
<script>

import {watchModifyMixin} from '@/mixins/watchModifyMixin';
import extractEnvironmentValuesMixin from '@/mixins/extractEnvironmentValuesMixin';
import {conditionTemplate, itemTemplate} from '@c/ConditionEditor/config';
import Modal from '@c/Modal';
import List from '@c/List';
import FormInput from '@f/Input';
import FormButton from '@f/Button';
import FormSelect from '@f/Select';
import Multiselect from '@vueform/multiselect';
import FormCheckbox from '@f/Check';
import FormRadio from '@f/Radio';
import FormTextArea from '@f/Textarea';
import Editor from '@tinymce/tinymce-vue';
import ConditionEditor from '@c/ConditionEditor';
import helpers from '@/tools/helpers';
import DropdownButton from '@f/DropdownButton';
import DropdownItem from '@f/DropdownButton/components/DropdownItem';
import EditAnswerConditions from '@page/Scenario/components/edit-answer-conditions';
import {mapQuestionBooleanProps} from '@page/Scenario/components/helpers/edit-question';
import Error from '@c/Error';

export default {
    name: 'EditQuestion',
    components: {
        Modal,
        FormInput,
        FormButton,
        FormSelect,
        Multiselect,
        FormCheckbox,
        FormRadio,
        List,
        Editor,
        FormTextArea,
        ConditionEditor,
        DropdownButton,
        DropdownItem,
        EditAnswerConditions,
        Error,
    },
    mixins: [
        watchModifyMixin('q', 'qInitial'),
        extractEnvironmentValuesMixin,
    ],
    props: {
        question: {
            type: Object,
            default() {
                return {};
            },
        },
        onSave: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        onCancel: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    emits: ['update:question'],
    data() {
        return {
            q: {...helpers.deepCopy(this.question)},
            qInitial: {...helpers.deepCopy(this.question)},
            errors: [],
        };
    },

    computed: {
        ...mapQuestionBooleanProps([
            'published',
            'important',
        ]),
        validated() {
            return !this.errors.length;
        },
    },
    watch: {
        q: {
            handler() {
                this.$emit('update:question', this.q);
            },
            deep: true,
        },
    },
    mounted() {
        this.validate();
    },
    methods: {
        removeAction(conditionKey) {
            this.q[conditionKey] = [];
        },
        addAction(conditionKey) {
            if (!this.q[conditionKey].length) {
                this.q[conditionKey] = [
                    ...helpers.deepCopy([{
                        ...itemTemplate,
                        conditions: [{...conditionTemplate}],
                        tmpKey: helpers.randomKey()
                    }]),
                ];
            }
        },
        validate() {
            const errors = [];
            if (!this.q.details?.name) {
                errors.push('Введите заголовок вопроса.');
            }

            this.errors = [...errors];
        },
    }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
