<div class="scenario-product-tree-actions">
    <form-button
        v-if="editProducts"
        type="a"
        class="btn btn-sm btn-blue text-center m-1"
        @click="call('editProducts')"
    >
        <template v-slot:center>
            <span class="icon edit green m-auto"></span>
        </template>
    </form-button>
</div>
