<div class="scenario-benefits">
    <ScenarioBenefitsFilter
        v-model="filter"
        class="mt-3"
    />

    <div class="row mt-3">
        <ScenarioBenefitsTree
            v-model="tree"
            :filter="filter"
            :disabled="loading"
            @update:modelValue="onTreeUpdate"
            @action="actionHandler"
        />
    </div>

    <div class="form-row mt-3">
        <div class="col">
            <div class="form-group-inline mr-2">
                <FormButton
                    label="Сохранить"
                    :attributes="{class:['btn-green', 'h36', 'inline']}"
                    :disabled="!modified || loading"
                    :preloader="loading"
                    @click="saveBenefitsTree"
                />
            </div>
            <div class="form-group-inline">
                <FormButton
                    label="Отменить"
                    :attributes="{class:['btn-gray', 'h36', 'inline']}"
                    :disabled="!modified || loading"
                    :preloader="loading"
                    @click="loadBenefitsTree"
                />
            </div>
        </div>
    </div>
</div>
