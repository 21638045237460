/**
 * Создает writable computed свойства, для полей детализации вопроса с типом булево
 *
 * @param {string[]} questionDetailKeys список ключей с типом булево
 */
export function mapQuestionBooleanProps(questionDetailKeys) {
    const props = {};

    questionDetailKeys.forEach(key => {
        props[key] = {
            get() {
                return parseInt(this.q.details[key], 10);
            },
            set(value) {
                this.q.details[key] = value.toString();
            }
        };
    });

    return props;
}
