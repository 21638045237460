<li class="tree__node-item">
    <div
        class="tree__level row"
    >
        <div class="tree__level-btns">

            <ToggleButton
                v-model="item.operator"
                :activeOnly="true"
                :options="[
                    {
                        label: 'И',
                        value: 'AND',
                    },
                    {
                        label: 'Или',
                        value: 'OR',
                    },
                ]"
            />

            <slot name="actions" :data="{item}"></slot>

            <slot name="extraPoolData" :data="item"></slot>
        </div>
    </div>
    <ul class="tree__node-group" v-if="item.pools.length || item.conditions.length">
        <ConditionPool
            v-for="(childItem, childItemIndex) in item.pools"
            :item="item.pools[childItemIndex]"
            :item-index="childItemIndex"
            :key="childItem.id || childItem.tmpKey"
        >
            <template v-slot:actions="itemData">
                <slot name="actions" :data="itemData.data"></slot>
            </template>
            <template v-slot:extraPoolData="poolData">
                <slot name="extraPoolData" :data="poolData.data"></slot>
            </template>
            <template v-slot:propertyTooltipData="conditionData">
                <slot name="propertyTooltipData" :data="conditionData.data"></slot>
            </template>
        </ConditionPool>

        <Condition
            v-for="(itemCondition, itemConditionIndex) in item.conditions"
            :condition="item.conditions[itemConditionIndex]"
            :key="itemCondition.id || itemCondition.tmpKey"
        >
            <template v-slot:addSlot>

            </template>

            <slot name="extraConditionData" :data="itemCondition"></slot>

            <template v-slot:propertyTooltipData="conditionData">
                <slot name="propertyTooltipData" :data="conditionData.data"></slot>
            </template>
        </Condition>
    </ul>
</li>
