<div class="scenario-benefits-tree">
    <tree
        :nodes="modelValue"
        label-key="title"
        expand-icon="chevron"
        expanded-icon="chevron chevron_rotate-down"
        :filter="filter.title"
        :filter-method="filterMethod"
    >
        <template #label="row">
            <div class="d-flex">
                <ToggleSwitch
                    v-if="row.data.itemType === constants.BENEFIT_TREE_ITEM_TYPE_BENEFIT"
                    :model-value="row.data.is_hidden"
                    :disabled="disabled"
                    true-value="0"
                    false-value="1"
                    class="mr-1"
                    @update:modelValue="changeBenefitVisibility(row.data, $event)"
                />
                <div
                    :class="{
                        'text-muted': row.data.is_hidden === '1',
                    }"
                >
                    <tippy
                        :content="row.data.content"
                        :key="`benefit-${row.data.id}`"
                        :delay="300"
                        allowHTML
                        theme="big"
                        placement="bottom"
                    >
                        <span :class="row.data.itemType">{{ row.data.title ? row.data.title : '<...>' }}</span>
                    </tippy>
                </div>
            </div>

            <ScenarioBenefitsTreeActions
                :edit-benefit="row.data.itemType === constants.BENEFIT_TREE_ITEM_TYPE_BENEFIT"
                @action="(args) => actionHandler({
                    ...args,
                    benefit: row.data,
                })"
            />
        </template>
    </tree>
</div>
