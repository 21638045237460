<div @focusout="onFocusOut" tabindex="0" class="dropdown">
    <div @click="toggle" ref="button">
        <slot name="button">
            <FormButton
                :label="buttonLabel"
                :attributes="attributes"
                :preloader="loading"
                :disabled="disabled"
            />
        </slot>
    </div>

    <transition name="dropdown-content">
        <div
            ref="content"
            v-if="showing"
            :style="`top: ${contentTop}px;`"
            class="dropdown-content__container bg-white position-absolute rounded py-1"
            role="menu"
            @click="onContentClick"
        >
            <slot />
        </div>
    </transition>
</div>
