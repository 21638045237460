<template src="../templates/condition-tree.html"></template>
<script>

import ConditionPool from './condition-pool';

export default {
    name: 'ConditionTree',
    components: {
        ConditionPool,
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
    },
};

</script>
