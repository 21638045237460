<template src="../templates/condition-property.html"></template>
<script>

import ConditionValue from './condition-value';

export default {
    name: 'ConditionProperty',
    components: {
        ConditionValue,
    },
    props: {
        property: {
            type: Object,
            default() {
                return {};
            },
        },
    },
};

</script>
