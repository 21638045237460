<div class="p-4 edit-scenario-products">
    <div class="row-col">
        <div class="col">
            <form-input
                class="w-100 border-bottom border-dark-gray pb-1"
                v-model="editData.name"
                :attributes="{
                    placeholder: 'Наименование сценария',
                    class: 'p-0 input-head'
                }"
            />
        </div>
    </div>

    <div class="row-col mb-3">
        <div class="col">
            <form-checkbox
                class="mt-3"
                v-model="published"
                label="Сценарий опубликован"
            />
        </div>
    </div>

    <div class="row-col mb-3">
        <div class="col">
            <form-input
                class="change-category w-50"
                :model-value="groupLabel"
                label="Группа"
                label-class="d-block"
                :attributes="{class:['btn-gray', 'pointer']}"
                @click.prevent="showGroupSelectDialog = true"
            />
        </div>
    </div>

    <div class="row-col mb-3">
        <div class="col">
            <h4 class="mb-1">Выберите группы товаров</h4>
            <Multiselect
                v-model="editData.productMachineCategories"
                :multiple="true"
                :options="productCategories"
                :loading="loading"
                object
                searchable
                required
                mode="tags"
                label="name"
                trackBy="name"
                key="id"
                valueProp="id"
                placeholder="Выбери группу товаров"
                no-options-text="Введите текст"
                no-results-text="Нет вариантов для выбора"
            />
        </div>
    </div>

    <template v-if="editData.productMachineCategories.length">
        <div class="row-col mb-3">
            <div class="col-3">
                <form-input
                    class="w-100"
                    v-model="productFilter"
                    label="Наименование товара"
                />
            </div>
            <div class="col-3">
                <form-input
                    class="w-100"
                    v-model="manufacturerFilter"
                    label="Производитель"
                />
            </div>
            <div class="col-6">
                <label class="h6">Группы товаров</label>
                <Multiselect
                    v-model="productCategoriesFilter"
                    :multiple="true"
                    :options="editData.productMachineCategories"
                    object
                    searchable
                    mode="tags"
                    label="name"
                    trackBy="name"
                    key="id"
                    valueProp="id"
                    placeholder="Выберите группы товаров для фильтра"
                    no-options-text="Введите текст"
                    no-results-text="Нет вариантов для выбора"
                />
            </div>
        </div>

        <div class="row-col mb-3">
            <div class="col">
                <FormCheckbox
                        v-model="showOnlySelectedFilter"
                    label="Показать включенные в подбор"
                />
            </div>
        </div>

        <div class="row-col mb-3">
            <div class="col">
                <div class="edit-scenario-products__product_grid">
                    <grid
                        :columns="productGridColumns"
                        :data="filteredProducts"
                        class="product-grid"
                    >
                        <template #number="{ data: product }">
                            {{ filteredProducts.indexOf(product) + 1 }}
                        </template>
                        <template #manufacturer="{ data: product }">
                            {{ getManufacturerName(product.manufacturer_id) }}
                        </template>
                        <template #selected="{ data: product }">
                            <FormCheckbox
                                :model-value="typeof selectedProducts[product.id] !== 'undefined'"
                                @change="onSelectProducts([product.id], $event.target.checked)"
                            />
                        </template>
                        <template #hot="{ data: product }">
                            <FormCheckbox
                                v-if="parseInt(product.hot, 10) === 1"
                                :model-value="parseInt(product.hot, 10)"
                                :attributes="{
                                    disabled: true,
                                }"
                            />
                        </template>
                        <template #is_option="{ data: product }">
                            <FormCheckbox
                                v-if="parseInt(product.is_option, 10) === 1"
                                :model-value="parseInt(product.is_option, 10)"
                                :attributes="{
                                    disabled: true,
                                }"
                            />
                        </template>
                        <template #headerColumnLeftSlot="{ data: cell }">
                            <FormCheckbox
                                v-if="cell.name === 'selected'"
                                :model-value="filteredProductsAllSelected"
                                @change="toggleSelectedAll()"
                            />
                        </template>
                    </grid>
                </div>
            </div>
        </div>
    </template>

    <div class="row-col">
        <div class="col">
            <div class="position-sticky bottom-n80 action-panel pt-2">
                <div>
                    <form-button
                        :disabled="!validated"
                        label="Сохранить"
                        :attributes="{class: ['btn-green', 'mr-1']}"
                        @click="save"
                    />
                    <form-button label="Отмена" @click="onCancel" :attributes="{class: ['btn-gray']}"/>
                </div>
            </div>
        </div>
    </div>

    <teleport to="body">
        <tree-select-dialog
            v-model="editData.scenario_group_id"
            v-model:show="showGroupSelectDialog"
            :nodes="activeScenariosGroupTree"
            labelKey="name"
        >
            <template #name="row">
                <template v-if="parseInt(row.data.removed, 10) === 0">
                    {{row.data.name}}
                </template>
                <template v-else>
                    <s>{{row.data.name}}</s>
                </template>
            </template>
        </tree-select-dialog>
    </teleport>
</div>
