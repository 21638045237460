<div class="modal-section">
    <div class="form-row">
        <div class="col">
            <h2>{{benefitName}}</h2>
        </div>
    </div>
</div><div class="modal-section modal-section-secondary">
    <div class="form-row">
        <div class="col-12">
            <ConditionEditor
                v-model:items="conditionsEdited"
                :condition-property-source="conditionPropertySource"
            />
        </div>
    </div>
</div>
<div class="modal-section">
    <div class="form-row">
        <div class="col">
            <div class="form-group-inline mr-2">
                <FormButton
                    label="Сохранить"
                    :attributes="{class:['btn-green', 'h36', 'inline']}"
                    @click="onSave"
                />
            </div>
            <div class="form-group-inline mr-4">
                <FormButton
                    label="Отменить"
                    :attributes="{class:['btn-gray', 'h36', 'inline']}"
                    @click="onCancel"
                />
            </div>
        </div>
    </div>
</div>
