<div v-if="title" :class="['page-title row c flex-column', {sticky}, classWrap]">
	<div class="row d-flex no-gap align-items-center">
		<component :is="tag" :class="tag + ' col mb-0'">
			{{title_page ? title_page : title}}
		</component>
		<div v-if="date_prep" class="date col-auto">
			{{date_prep}}
		</div>
	</div>
	<div v-if="subtitle_prep" class="subtitle">
		{{subtitle_prep}}
	</div>
</div>
