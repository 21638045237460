<template src="./templates/breadcrumbs.html"></template>

<script>

import Button from '@f/Button';

export default {
    name: 'Breadcrumbs',
    components: {
        Button,
    },
    props: {
        items: {
            type: Array,
            default() {
                return [{
                    title: 'Test',
                    href: '/',
                    onClick: () => {
                    },
                }];
            },
        },
        class: {
            type: String,
            default() {
                return 'btn-sm btn-white';
            },
        },
    },
    methods: {
        buildAttributes(item) {
            const attrs = {class: this.class};
            
            if (typeof item.href !== 'undefined' && item.href) {
                attrs.type = 'a';
                attrs.href = item.href;
            }
            if (typeof item.class !== 'undefined' && item.class) {
                attrs.class = item.class;
            }
            return attrs;
        },
        buildListeners(item) {
            const listeners = {};
            if (typeof item.onClick !== 'undefined') {
                listeners.click = () => { item.onClick(); };
            }
            return listeners;
        },
    },
};
</script>

