<template src="./templates/toggle-switch.html" />

<script>
export default {
    name: 'ToggleSwitch',
    props: {
        modelValue: {
            type: null,
            default: false,
        },
        trueValue: {
            type: null,
            default: true,
        },
        falseValue: {
            type: null,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        leftLabel: {
            type: String,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // Ширина toggle в пикселях, высота изменяется пропорционально
        width: {
            type: Number,
            default: 26,
        },
        // Можно переопределить фон переключателя в положении true
        activeTrackClass: {
            type: String,
            default: 'bg-green',
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            checked: this.modelValue === this.trueValue,
        };
    },
    computed: {
        indicatorSize() {
            return Math.round(this.width / 2.2);
        },
        indicatorStyle() {
            return {
                height: `${this.indicatorSize}px`,
                width: `${this.indicatorSize}px`,
            };
        },
        trackStyle() {
            return {
                width: `${this.width}px`,
                height: `${this.indicatorSize + 4}px`,
            };
        },
    },
    watch: {
        modelValue() {
            this.checked = this.modelValue === this.trueValue;
        },
        checked() {
            this.$emit(
                'update:modelValue',
                this.checked ? this.trueValue : this.falseValue
            );
        },
    },
};
</script>

<style lang="scss" src="./styles/toggle-switch.scss" />
