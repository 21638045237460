<template src="../templates/edit-scenario-conditions.html" />

<script>
import helpers from '@/tools/helpers';
import {watchModifyMixin} from '@/mixins/watchModifyMixin';
import ConditionEditor from '@c/ConditionEditor';
import FormButton from '@f/Button';

export default {
    name: 'EditScenarioConditions',
    components: {
        ConditionEditor,
        FormButton,
    },
    mixins: [
        watchModifyMixin('conditionsEdited', 'conditionsInitial'),
    ],
    props: {
        conditionsData: {
            type: Array,
            default: () => [],
            required: true,
        },
        onSave: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
        onCancel: {
            type: Function,
            default() {
                return Function.prototype;
            }
        },
    },
    data() {
        return {
            conditionsEdited: helpers.deepCopy(this.conditionsData),
            conditionsInitial: helpers.deepCopy(this.conditionsData),
        };
    },
    methods: {
        save() {
            this.onSave(this.conditionsEdited);
        },
    }
};
</script>
