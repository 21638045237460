<div class="condition-viewer">
    <ConditionTree :items="items">
        <template v-slot:actions="slotData">
            <!-- pool actions-->
        </template>

        <template v-slot:extraPoolData="poolData">
            <slot name="extraPoolData" :data="poolData.data"></slot>
        </template>

        <template v-slot:extraConditionData="itemData">
            <slot name="extraConditionData" :data="itemData.data"></slot>
        </template>

        <template v-slot:propertyTooltipData="conditionData">
            <slot name="propertyTooltipData" :data="conditionData.data"></slot>
        </template>
    </ConditionTree>
</div>
