<template src="../templates/condition.html"></template>
<script>

import {catalogs} from '../config';
import ConditionProperty from './condition-property';
import ConditionValue from './condition-value';

export default {
    name: 'Condition',
    components: {
        ConditionValue,
        ConditionProperty,
    },
    inject: ['shownElements'],
    props: {
        condition: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        show() {
            return this.shownElements;
        }
    },
    data() {
        return {
            catalogs: {...catalogs},
        };
    },
};

</script>
