export const catalogs = {
    comparison_operators: [
        {title: 'Равно', value: 'EQUAL'},
        {title: 'Не равно', value: 'NOT_EQUAL'},
        {title: 'Больше', value: 'MORE'},
        {title: 'Больше либо равно', value: 'EQUAL_MORE'},
        {title: 'Меньше', value: 'LESS'},
        {title: 'Меньше либо равно', value: 'EQUAL_LESS'},
        {title: 'Входит в список', value: 'IN'},
        {title: 'Не входит в список', value: 'NOT_IN'},
        {title: 'В диапазоне', value: 'BETWEEN'},
        {title: 'Не в диапазоне', value: 'NOT_BETWEEN'},
        {title: 'Является пустым', value: 'EMPTY_VALUE'},
    ]
};
