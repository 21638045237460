<div>
    <div class="modal-section">
        <div class="row-col mw-2">
            <div class="col-11 ph-3">
                <div class="form-row m-0">
                    <div class="col-1">
                        <span class="icon help-circle gray lg x5 m-auto"></span>
                    </div>
                    <div class="col-10">
                        <FormTextArea
                            :attributes="{
                                placeholder: q.id ? 'Заголовок вопроса' : 'Новый вопрос',
                                name: 'description',
                                class: 'input-head'
                            }"
                            v-model="q.details.name"
                            flexible
                            @update:model-value="validate"
                        />

                    </div>
                    <div class="col-content">
                        <p v-if="q.id" class="text-muted">R-{{ q.id.toString().padStart(5, '0') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-section modal-section-no-b">
        <div class="form-row">
            <div class="col">
                <div class="form-group">
                    <label class="h6">Описание</label>
                    <Editor
                        :api-key="tinyMCEApiKey"
                        :init="{
                            menubar: false,
                            relative_urls: true,
                            convert_urls: false,
                            statusbar: false,
                            plugins: [
                               'advlist autolink lists link image charmap print preview anchor',
                               'media table paste autoresize'
                            ],
                            toolbar:
                                'undo redo | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent link',
                            autoresize: true,
                            min_height: 30
                        }"
                        v-model="q.details.description_manager"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-section" v-if="q.type === 'ANSWER'">
        <DropdownButton
            label="Добавить действие"
            :attributes="{
                'class': ['btn-blue', 'p-4-8'],
            }"
        >
            <DropdownItem
                v-if="!q.answerSelectionConditions.length"
                @click="addAction('answerSelectionConditions')"
            >
                Задать предусловие
            </DropdownItem>
            <DropdownItem
                v-if="!q.productSelectionConditions.length"
                @click="addAction('productSelectionConditions')"
            >
                Подбор по ТТХ
            </DropdownItem>
        </DropdownButton>
    </div>

    <div
        class="modal-section modal-section-secondary"
        v-if="q.answerSelectionConditions.length"
    >
        <EditAnswerConditions
            v-model:conditions="q.answerSelectionConditions"
            :conditionPropertySource="`PARTNER`"
            label="Задать предусловие"
            @remove="removeAction('answerSelectionConditions')"
        />
    </div>
    <div
        class="modal-section modal-section-secondary"
        v-if="q.productSelectionConditions.length"
    >
        <EditAnswerConditions
            v-model:conditions="q.productSelectionConditions"
            :conditionPropertySource="`PRODUCT_FEATURE`"
            label="Подбор товара"
            @remove="removeAction('productSelectionConditions')"
        />
    </div>

    <div class="modal-section">
        <Error v-for="(error, index) in errors" :key="index" :message="error" />
    </div>

    <div class="modal-section">
        <div class="form-row">
            <div class="col">
                <div class="form-group-inline mr-2">
                    <FormButton
                        label="Сохранить"
                        :disabled="!validated"
                        :attributes="{class:['btn-green', 'h36', 'inline']}"
                        @click="onSave"
                    />
                </div>
                <div class="form-group-inline mr-4">
                    <FormButton
                        label="Отменить"
                        :attributes="{class:['btn-gray', 'h36', 'inline']}"
                        @click="onCancel"
                    />
                </div>
                <div class="form-group-inline mr-2">
                    <FormCheckbox
                        label="Опубликовать"
                        v-model="q.details.published"
                        wrapper-class="inline mt-1"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
