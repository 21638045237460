<template src="../templates/scenario-conditions.html" />

<script>
import ScenarioConditionsActions from '@page/Scenario/components/scenario-conditions-actions';
import ExpansionItem from '@c/ExpansionItem';
import ConditionEditor from '@c/ConditionEditor';
import ConditionViewer from '@c/ConditionViewer';

export default {
    name: 'ScenarioConditions',
    components: {
        ExpansionItem,
        ScenarioConditionsActions,
        ConditionEditor,
        ConditionViewer,
    },
    props: {
        conditions: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['action'],
    data() {
        return {
            blockExpanded: false,
        };
    },
    methods: {
        actionHandler(args) {
            this.$emit('action', args);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../styles/scenario-conditions';
</style>
