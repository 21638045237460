<template src="./templates/index.html"></template>
<script>

import {computed} from 'vue';
import ConditionTree from './components/condition-tree';
import './styles.scss';

export default {
    name: 'ConditionViewer',
    components: {
        ConditionTree,
    },
    // даём доступ всем чайлдам
    provide() {
        return {
            shownElements: computed(() => this.shownElements),
        };
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        // кастомные настройки отображения элементов компонента
        shownElements: {
            type: Array,
            default() {
                return ['points'];
            },
        }
    },
};

</script>
