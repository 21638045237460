<template src="../templates/scenario-benefits-filter.html" />

<script>
import FormInput from '@f/Input';
import FormCheckbox from '@f/Check';
import helpers from '@/tools/helpers';
import {getBenefitFilterTemplate} from '@page/Scenario/config';

export default {
    name: 'ScenarioBenefitsFilter',
    components: {
        FormInput,
        FormCheckbox,
    },
    props: {
        modelValue: {
            type: Object,
            default: getBenefitFilterTemplate(),
            required: true,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            filter: helpers.deepCopy(this.modelValue),
        };
    },
    watch: {
        filter: {
            handler() {
                this.$emit('update:modelValue', this.filter);
            },
            deep: true,
        },
    },
};
</script>
