<div class="scenario-product-tree">
    <tree
        :nodes="productTree"
        label-key="name"
        expand-icon="chevron"
        expanded-icon="chevron chevron_rotate-down"
    >
        <template v-slot:label="row">
            {{row.data.name}}
            <ScenarioProductTreeActions
                :edit-products="!row.data.hasOwnProperty('id')"
                @action="actionHandler"
            />
        </template>
    </tree>
</div>
