<template src="./templates/index.html"></template>

<script>
import {computed} from 'vue';
import Confirm from '@c/Confirm';
import PageTitle from '@c/PageTitle';
import Breadcrumbs from '@c/Breadcrumbs';
import PageHeader from '@c/Header';
import Modal from '@c/Modal';
import Loader from '@c/Loader';
import ScenarioProductTree from '@page/Scenario/components/scenario-product-tree';
import EditScenarioProducts from '@page/Scenario/components/edit-scenario-products';
import EditQuestion from '@page/Scenario/components/edit-question';
import Grid from '@c/Grid';
import FormButton from '@f/Button';
import FormCheckbox from '@f/Check';
import EditQuestionActions from '@page/Scenario/components/edit-question-actions';
import EditBenefitConditions from '@page/Scenario/components/edit-benefit-conditions';

import ExpansionItem from '@c/ExpansionItem';

import {
    getScenarios,
    saveScenario,
    getQuestions,
    getRules,
    saveQuestion,
    removeQuestion,
    restoreQuestion,
    getBenefitsTree,
    saveBenefitsTree,
    getBenefitConditions,
    saveBenefitConditions,
    getScenarioConditions,
    saveScenarioConditions
} from '@api/scenarios';
import {getQuestionTemplate} from '@page/Scenario/config';
import ScenarioBenefits from '@page/Scenario/components/scenario-benefits';
import ScenarioConditions from '@page/Scenario/components/scenario-conditions';
import EditScenarioConditions from '@page/Scenario/components/edit-scenario-conditions';
import ConditionViewer from '@c/ConditionViewer';
import Tabs from '@c/Tabs';
import helpers from '@/tools/helpers';
import AddLine from '@c/AddLine';

export default {
    name: 'Index',
    components: {
        AddLine,
        Tabs,
        ConditionViewer,
        PageTitle,
        Breadcrumbs,
        PageHeader,
        Modal,
        Loader,
        ScenarioProductTree,
        Confirm,
        EditScenarioProducts,
        EditQuestion,
        Grid,
        FormButton,
        FormCheckbox,
        EditQuestionActions,
        ExpansionItem,
        EditBenefitConditions,
        ScenarioBenefits,
        ScenarioConditions,
        EditScenarioConditions,
    },
    provide() {
        return {
            // предоставляем чайлдам доступ к списку категорий товаров
            scenarioProductCategories: computed(
                () => {
                    if (this.scenarioData !== null) {
                        return this.scenarioData.productMachineCategories || [];
                    }

                    return [];
                }
            )
        };
    },
    props: {
        id: {
            type: String,
            default() {
                return null;
            }
        },
    },
    data() {
        return {
            scenarioData: null,
            editScenarioData: null,
            showEditScenarioProductsModal: false,

            showEditQuestionModal: false,
            showSelectionBlock: false,
            selectedQuestionData: getQuestionTemplate(),
            selectedQuestionDataEdit: null,

            showEditBenefitConditionsModal: false,
            benefitConditionsEdited: [],
            benefitEdited: {},

            showConfirmModal: false,
            confirmBts: {
                title: '',
                buttons: []
            },

            questions: [],
            questionsGridColumns: [
                {
                    slot_name: 'name',
                    name: 'name',
                    label: 'Вопрос',
                    width: 'minmax(200px, 1fr)',
                },
                {
                    slot_name: 'weight',
                    name: 'weight',
                    label: 'Вес вопроса',
                    width: 'minmax(50px, 0.3fr)',
                },
                {
                    slot_name: 'action',
                    name: 'action',
                    label: 'Действие',
                    width: 'minmax(50px, 0.3fr)',
                },
                {
                    slot_name: 'selection',
                    name: 'selection',
                    label: 'Подбор',
                    width: 'minmax(50px, 0.3fr)',
                },
                {
                    slot_name: 'published',
                    name: 'published',
                    label: 'Опубликован',
                    width: 'minmax(50px, 0.3fr)',
                },
                {
                    slot_name: 'actions',
                    name: 'actions',
                    label: '',
                    width: 'minmax(50px, 0.3fr)',
                },
            ],
            questionsFilter: {
                published: 0,
                selection: 0,
                answerSelection: 0,
                showRemoved: 0,
            },

            rulesGridColumns: [
                {
                    slot_name: 'name',
                    name: 'name',
                    label: 'Правило',
                    width: '30%',
                },
                {
                    slot_name: 'precondition',
                    name: 'precondition',
                    label: 'Предусловие',
                    width: '20%',
                },
                {
                    slot_name: 'selection',
                    name: 'selection',
                    label: 'Рейтинг по ТТХ',
                    width: '20%',
                },
                {
                    slot_name: 'published',
                    name: 'published',
                    label: 'Опубликовано',
                    width: '20%',
                },
                {
                    slot_name: 'actions',
                    name: 'actions',
                    label: '',
                    width: '10%',
                },
            ],
            visibleRules: [],

            rulesRulesTypeTabs: [
                {
                    id: 'answerSelectionConditions',
                    slot_name: 'answerSelectionConditions',
                    label: 'Предусловия',
                    width: 'minmax(100px, 3.33fr)',
                },
                {
                    id: 'productSelectionConditions',
                    slot_name: 'productSelectionConditions',
                    label: 'Подбор',
                    width: 'minmax(120px, 3.33fr)',
                }
            ],

            benefitsTreeData: [],
            benefitsLoading: false,

            scenarioConditions: null,
            scenarioConditionsEditData: null,
            showEditScenarioConditionsModal: false,
        };
    },
    computed: {
        filteredQuestions() {
            const filtered = [...helpers.deepCopy(this.questions)].filter(
                v => this.filterEntities(
                    v,
                    ['published', 'showRemoved']
                )
            );
            filtered.forEach((i, k) => {
                filtered[k].answers = [...helpers.deepCopy(filtered[k].answers)].filter(
                    v => this.filterEntities(
                        v,
                        ['published', 'selection', 'answerSelection', 'showRemoved']
                    )
                );
            });

            return filtered;
        },
    },
    async mounted() {
        await getScenarios(
            {id: this.id},
            r => {
                if (Object.hasOwn(r, this.id)) {
                    this.scenarioData = {...r[this.id]};
                } else {
                    this.$router.replace({name: 'NotFound'});
                }
            }
        );
        await Promise.all([
            this.initScenarioQuestions(),
            this.loadBenefitsTree(),
            this.loadScenarioConditions(),
        ]);
    },
    methods: {
        actionHandler(args) {
            switch (args.action) {
                case 'editProducts':
                    this.editScenarioProducts();
                    break;
                case 'editQuestion':
                    this.editScenarioQuestion(args.id);
                    break;
                case 'removeQuestion':
                    this.removeScenarioQuestion(args.id);
                    break;
                case 'restoreQuestion':
                    this.restoreScenarioQuestion(args.id);
                    break;
                case 'saveBenefitsTree':
                    this.saveBenefitsTree();
                    break;
                case 'loadBenefitsTree':
                    this.loadBenefitsTree();
                    break;
                case 'editBenefit':
                    this.editBenefitConditions(args.benefit);
                    break;
                case 'editScenarioConditions':
                    this.editScenarioConditions();
                    break;
                default:
                    console.log('Неизвестное действие', args);
            }
        },
        saveScenario(data) {
            saveScenario(
                {...data},
                r => {
                    this.scenarioData = {...r};
                    this.showEditScenarioProductsModal = false;
                    this.loadBenefitsTree();
                }
            );
        },
        checkModifyAndCloseModal(ref, showModalProperty, saveCallback) {
            this.confirmBts = {
                title: 'Данные были изменены. Сохранить изменения?',
                buttons: [
                    {
                        label: 'Да',
                        class: 'btn-green',
                        disabled: Object.hasOwn(this.$refs[ref], 'validated')
                            && !this.$refs[ref].validated,
                        action: () => {
                            this.showConfirmModal = false;
                            this.saveScenarioQuestion();
                        },
                    },
                    {
                        label: 'Нет',
                        class: 'btn-red',
                        action: () => {
                            this[showModalProperty] = false;
                            this.showConfirmModal = false;
                        },
                    },
                    {
                        label: 'Отмена',
                        action: () => {
                            this.showConfirmModal = false;
                        },
                    },
                ]
            };

            if (this.$refs?.[ref]?.modified) {
                this.showConfirmModal = true;
            } else {
                this[showModalProperty] = false;
            }
        },
        closeEditScenarioModal() {
            this.checkModifyAndCloseModal(
                'editScenarioProducts',
                'showEditScenarioProductsModal',
                this.$refs.editScenarioProducts.save
            );
        },
        initScenarioQuestions() {
            return getQuestions(
                this.id,
                {},
                questions => {
                    this.questions = Object.values(questions);
                }
            );
        },
        saveScenarioQuestion() {
            saveQuestion(
                {...this.selectedQuestionDataEdit},
                r => {
                    this.selectedQuestionData = {...r};

                    this.initScenarioQuestions();
                    this.showEditQuestionModal = false;
                    this.selectedQuestionDataEdit = null;
                }
            );
        },
        closeEditQuestionModal() {
            this.checkModifyAndCloseModal(
                'editScenarioQuestion',
                'showEditQuestionModal',
                this.saveScenarioQuestion
            );
        },
        closeEditBenefitConditionsModal() {
            this.checkModifyAndCloseModal(
                'editBenefitConditions',
                'showEditBenefitConditionsModal',
                this.saveBenefitConditions
            );
        },
        editScenarioProducts() {
            getScenarios(
                {id: this.id},
                r => {
                    this.editScenarioData = {...r[this.id]};
                    this.showEditScenarioProductsModal = true;
                }
            );
        },
        editScenarioQuestion(id = 0, customData = {}) {
            if (id !== 0) {
                getRules(
                    {id},
                    r => {
                        this.selectedQuestionDataEdit = {...r[id]};
                        this.showEditQuestionModal = true;
                    }
                );
            } else {
                this.selectedQuestionDataEdit = {...getQuestionTemplate(), scenarioId: this.id, ...customData};
                this.showEditQuestionModal = true;
            }
        },
        removeScenarioQuestion(id) {
            getQuestions(
                this.id,
                {
                    questionFilter: {
                        id,
                        limit: 1,
                        removed: 0,
                    }
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите удалить вопрос "${r[id].details.name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    removeQuestion(
                                        id,
                                        () => {
                                            this.initScenarioQuestions();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                },
                            },
                        ]
                    };

                    this.showConfirmModal = true;
                }
            );

        },
        restoreScenarioQuestion(id) {
            getQuestions(
                this.id,
                {
                    questionFilter: {
                        id,
                        limit: 1,
                        removed: 1,
                    }
                },
                r => {
                    this.confirmBts = {
                        title: `Вы уверены, что хотите восстановить вопрос "${r[id].details.name}"?`,
                        buttons: [
                            {
                                label: 'Да',
                                class: 'btn-red',
                                action: () => {
                                    restoreQuestion(
                                        id,
                                        () => {
                                            this.initScenarioQuestions();
                                            this.showConfirmModal = false;
                                        }
                                    );
                                },
                            },
                            {
                                label: 'Отмена',
                                action: () => {
                                    this.showConfirmModal = false;
                                },
                            },
                        ]
                    };

                    this.showConfirmModal = true;
                }
            );
        },
        async loadBenefitsTree() {
            this.benefitsLoading = true;
            await getBenefitsTree(this.id, response => {
                this.benefitsTreeData = response;
                if (this.$refs.scenarioBenefits) {
                    this.$refs.scenarioBenefits.resetModifiedFlag();
                }
            });
            this.benefitsLoading = false;
        },
        async saveBenefitsTree() {
            this.benefitsLoading = true;
            await saveBenefitsTree(this.id, this.benefitsTreeData, response => {
                this.benefitsTreeData = response;
                if (this.$refs.scenarioBenefits) {
                    this.$refs.scenarioBenefits.resetModifiedFlag();
                }
            });
            this.benefitsLoading = false;
        },
        async loadBenefitConditions(benefit) {
             await getBenefitConditions(this.id, benefit.model_id, benefit.id, response => {
                this.benefitConditionsEdited = response;
                this.benefitEdited = benefit;
            });
        },
        async editBenefitConditions(benefit) {
            await this.loadBenefitConditions(benefit);
            this.showEditBenefitConditionsModal = true;
        },
        async saveBenefitConditions() {
            await saveBenefitConditions(
                this.id,
                this.benefitEdited.model_id,
                this.benefitEdited.id,
                this.benefitConditionsEdited,
                () => {
                    this.showEditBenefitConditionsModal = false;
                }
            );
        },
        async loadScenarioConditions() {
            await getScenarioConditions(this.id, response => {
                this.scenarioConditions = response;
            });
        },
        editScenarioConditions() {
            getScenarioConditions(
                this.id,
                response => {
                    this.scenarioConditionsEditData = response;
                    this.showEditScenarioConditionsModal = true;
                }
            );
        },
        closeEditScenarioConditionsModal() {
            this.checkModifyAndCloseModal(
                'editScenarioConditions',
                'showEditScenarioConditionsModal',
                this.$refs.editScenarioConditions.save
            );
        },
        saveScenarioConditions(conditions) {
            saveScenarioConditions(this.id, conditions, () => {
                this.loadScenarioConditions();
                this.showEditScenarioConditionsModal = false;
            });
        },
        toggleRules(id) {
            if (this.visibleRules.includes(id)) {
                this.visibleRules = this.visibleRules.filter(item => item !== id);
            } else {
                this.visibleRules.push(id);
            }
        },
        checkProperty(property, data) {
            switch (property) {
                case 'answerSelectionConditions':
                case 'productSelectionConditions':
                    if (data[property].length > 0) {
                        return '+';
                    }
                    break;
                default:
                    console.log(data, 'Error 58650: неизвестное имя свойства');
            }

            return '-';
        },
        filterEntities(value, rules = ['published', 'selection', 'answerSelection', 'showRemoved']) {

            const MX = {
                published: (v, flag) => !flag || !v.details.published || parseInt(v.details.published, 10) === 0,
                selection: (v, flag) => (flag && v.productSelectionConditions.length > 0) || !flag,
                answerSelection: (v, flag) => (flag && v.answerSelectionConditions.length > 0) || !flag,
                showRemoved: (v, flag) => (!flag && parseInt(v.removed, 10) === 0) || flag,
            };

            let flag = true;
            rules.forEach(filterFlag => {
                if (flag) {
                    flag = MX[filterFlag](value, !!this.questionsFilter[filterFlag]);
                }
            });

            return flag;
        },
    },
};
</script>

<style lang="scss" src="./styles/scenario.scss" />
