<template src="./templates/page-title.html"></template>

<script>
	export default {
		name: 'PageTitle',
		props: {
            sticky: {
                type: Boolean,
                default() {
                    return false;
                },
            },
			title: {
				type: String,
				default() {
					return null;
				}
			},
			params: {
				type: Object,
				default() {
					return {};
				}
			},
            classWrap: {
                type: String,
                default() {
                    return '';
                }
            },
		},
		computed: {
            title_page() {
                document.title = this.title;
                return (typeof this.params.title !== 'undefined') ? this.params.title : this.title;
            },
			tag() {
				return (typeof this.params.titleTag !== 'undefined') ? this.params.titleTag : 'h1';
			},
			date_prep() {
				return (typeof this.params.date !== 'undefined') ? this.params.date : null;
			},
			subtitle_prep() {
				return (typeof this.params.subtitle !== 'undefined') ? this.params.subtitle : null;
			}
		}
	};
</script>

