<template v-if="controlView === false">
Вид отображения неопределён
</template>

<template v-else-if="controlView === 'INPUT'">
    {{values[0]}}
</template>

<template v-else-if="controlView === 'INPUT_BETWEEN'">
    {{values[0]}}
    -
    {{values[1]}}
</template>

<template v-else-if="controlView === 'DROPDOWN'">
    {{options.find(o => o.value == values[0])?.title}}
</template>

<template v-else-if="controlView === 'DROPDOWN_BETWEEN'">
    {{options.find(o => o.value == values[0])?.title}}
    -
    {{options.find(o => o.value == values[1])?.title}}
</template>

<template v-else-if="controlView === 'INPUT_GROUP'">
    <template v-for="(v, i) in values">
        {{values[i]}}{{'; '}}
    </template>
</template>

<template v-else-if="controlView === 'DROPDOWN_GROUP'">
    <template v-for="(v, i) in values">
        {{options.find(o => o.value == values[i])?.title}}{{'; '}}
    </template>

</template>

<template v-else-if="controlView === 'BOOLEAN'">
    {{boolOptions.find(o => o.value == values[0])?.title}}
</template>

<template v-else-if="controlView === 'MULTI_DROPDOWN'">
    <template v-for="(v, i) in values">
        {{options.find(o => o.value == values[i])?.title}}{{'; '}}
    </template>
</template>

<template v-else-if="controlView === 'EMPTY_VALUE'">
    {{`null`}}
</template>
