<template src="../templates/scenario-benefits.html" />

<script>
import {watchModifyMixin} from '@/mixins/watchModifyMixin';
import ScenarioBenefitsTree from '@page/Scenario/components/scenario-benefits-tree';
import FormButton from '@f/Button';
import helpers from '@/tools/helpers';
import ScenarioBenefitsFilter from '@page/Scenario/components/scenario-benefits-filter';
import {getBenefitFilterTemplate} from '@page/Scenario/config';

export default {
    name: 'ScenarioBenefits',
    components: {
        ScenarioBenefitsTree,
        ScenarioBenefitsFilter,
        FormButton,
    },
    mixins: [
        watchModifyMixin('tree', 'treeInit'),
    ],
    props: {
        benefitsTreeData: {
            type: Array,
            default: () => [],
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['action', 'update:benefitsTreeData'],
    data() {
        return {
            tree: [],
            treeInit: [],
            filter: getBenefitFilterTemplate(),
        };
    },
    watch: {
        benefitsTreeData: {
            handler() {
                this.tree = helpers.deepCopy(this.benefitsTreeData);
                this.treeInit = helpers.deepCopy(this.benefitsTreeData);
            },
            immediate: true,
        },
    },
    methods: {
        actionHandler(args) {
            this.$emit('action', args);
        },
        onTreeUpdate() {
            this.$emit('update:benefitsTreeData', this.tree);
        },
        saveBenefitsTree() {
            this.actionHandler({action: 'saveBenefitsTree', id: null});
        },
        loadBenefitsTree() {
            this.actionHandler({action: 'loadBenefitsTree', id: null});
        },
        resetModifiedFlag() {
            this.modified = false;
            this.addModifyWatcher();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../styles/scenario-benefits';
</style>
