<div>
    <form-button
        v-if="editQuestion"
        type="a"
        class="btn btn-sm btn-blue text-center m-1"
        @click="call('editQuestion', id, type)"
    >
        <template v-slot:center>
            <span class="icon edit green m-auto"></span>
        </template>
    </form-button>

    <form-button
        v-if="removeQuestion"
        type="a"
        class="btn btn-sm btn-red m-auto text-center"
        @click="call('removeQuestion', id)"
    >
        <template v-slot:center>
            <span class="icon delete red m-auto"></span>
        </template>
    </form-button>

    <form-button
        v-if="restoreQuestion"
        type="a"
        class="btn btn-sm btn-red m-auto text-center"
        @click="call('restoreQuestion', id)"
    >
        <template v-slot:center>
            <span class="icon delete red m-auto"></span>
        </template>
    </form-button>
</div>
