<template src="../templates/scenario-product-tree.html" />

<script>
import Tree from '@c/Tree';
import ScenarioProductTreeActions from '@page/Scenario/components/scenario-product-tree-actions';

export default {
    name: 'ScenarioProductTree',
    components: {
        Tree,
        ScenarioProductTreeActions,
    },
    props: {
        scenarioData: {
            type: Object,
            default: () => ({}),
        }
    },
    emits: ['action'],
    computed: {
        productTree() {
            return [{
                name: 'Группы товаров и проблемы',
                children: this.scenarioData.productMachineCategories.map(category => ({...category})),
            }];
        }
    },
    methods: {
        actionHandler(args) {
            this.$emit('action', args);
        },
    }
};
</script>

<style src="../styles/scenario-product-tree.scss" lang="scss" />
