<template src="./templates/tree-select-dialog.html"></template>

<script>
import Modal from '@c/Modal';
import Tree from '@c/Tree';
import FormButton from '@c/Form/Button';
import FormInput from '@c/Form/Input';

export default {
    name: 'TreeSelectDialog',
    components: {
        FormButton,
        FormInput,
        Modal,
        Tree,
    },
    props: {
        // Идентификатор выбранной строки
        modelValue: {
            type: [Number, String],
            default: null,
            required: true,
        },
        // Показать/скрыть диалог выбора
        show: {
            type: Boolean,
            default: false,
        },
        // Дерево
        nodes: {
            type: Array,
            default() {
                return [
                    {
                        id: 1,
                        name: 'Root1',
                        children: [
                            {
                                id: 3,
                                name: 'Chil11',
                                children: [],
                            },
                            {
                                id: 4,
                                name: 'Chil12',
                                children: [
                                    {
                                        id: 5,
                                        name: 'Chil31',
                                        children: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        id: 2,
                        name: 'Root2',
                        children: [],
                    },
                    {
                        id: 3,
                        name: 'Root3',
                        children: [],
                    },
                ];
            },
            required: true,
        },
        // В каком свойстве хранится уникальный идентификатор строки дерева (эмитится в modelValue при выборе)
        nodeKey: {
            type: String,
            default: 'id',
        },
        // В каком свойстве хранится label для отображения
        labelKey: {
            type: String,
            default: 'label',
        },
        // В каком свойстве хранится список детей
        childrenKey: {
            type: String,
            default: 'children',
        },
    },
    emits: [
        'update:modelValue',
        'update:show',
    ],
    data() {
        return {
            selectedValue: this.modelValue,
            filter: '',
        };
    },
    watch: {
        selectedValue() {
            this.$emit('update:modelValue', this.selectedValue);
        },
    },
    methods: {
        closeDialog() {
            this.filter = '';
            this.$emit('update:show', false);
        },
    },
};
</script>
