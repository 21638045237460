<template src="../templates/condition-pool.html"></template>
<script>

import ToggleButton from '@c/ToggleButton';
import Condition from './condition';

export default {
    name: 'ConditionPool',
    components: {
        ToggleButton,
        Condition,
    },
    props: {
        item: {
            type: Object,
            default() {
                return {};
            },
        },
        itemIndex: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
};

</script>
