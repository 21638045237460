<ExpansionItem
    v-model="blockExpanded"
    class="scenario-conditions mb-1"
    header-class="scenario__expansion-block"
>
    <template v-slot:label>
        Условие сценария
        <ScenarioConditionsActions @action="actionHandler" />
    </template>
    <div class="form-row my-2">
        <div class="col">
            <ConditionViewer
                :items="conditions || []"
                :shown-elements="[]"
            />
        </div>
    </div>
</ExpansionItem>
