<template src="../templates/edit-question-actions.html"></template>
<script>

import FormButton from '@f/Button';

export default {
    name: 'EditQuestionActions',
    components: {
        FormButton,
    },
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: true,
            default: null,
        },
        editQuestion: {
            type: Boolean,
            default: true,
        },
        removeQuestion: {
            type: Boolean,
            default: true,
        },
        restoreQuestion: {
            type: Boolean,
            default: false,
        },
            },
    emits: ['action'],
    methods: {
        call(action, id) {
            this.$emit('action', {action, id});
        }
    }
};
</script>
