<template src="../templates/scenario-benefits-tree.html" />

<script>
import Tree from '@c/Tree';
import {BENEFIT_TREE_ITEM_TYPE_BENEFIT, getBenefitFilterTemplate} from '@page/Scenario/config';
import ToggleSwitch from '@c/ToggleSwitch';
import ScenarioBenefitsTreeActions from '@page/Scenario/components/scenario-benefits-tree-actions';
import helpers from '@/tools/helpers';

export default {
    name: 'ScenarioBenefitsTree',
    components: {
        Tree,
        ToggleSwitch,
        ScenarioBenefitsTreeActions,
    },
    props: {
        modelValue: {
            type: Array,
            default: () => [],
            required: true,
        },
        filter: {
            type: Object,
            default: getBenefitFilterTemplate(),
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'action'],
    data() {
        return {
            constants: Object.freeze({
                BENEFIT_TREE_ITEM_TYPE_BENEFIT,
            }),
        };
    },
    computed: {
        filterMethod() {
            return (node, searchText) => {
                const textIncludes = node.title?.toLowerCase().includes(searchText?.toLowerCase());
                const hiddenBenefitsCheck = this.filter.showHiddenBenefits || node.is_hidden === '0';

                return textIncludes && hiddenBenefitsCheck;
            };
        }
    },
    methods: {
        actionHandler(args) {
            this.$emit('action', args);
        },
        changeBenefitVisibility(benefit, isHidden) {
            // Находим выгоду в дереве -> меняем значение признака is_hidden -> эмитим изменённое дерево
            const tree = helpers.deepCopy(this.modelValue);
            const node = helpers.findNodeInTree(
                tree,
                item => item.id === benefit.id && item.model_id === benefit.model_id
            );
            if (node) {
                node.is_hidden = isHidden;
                this.$emit('update:modelValue', tree);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../styles/scenario-benefits';
</style>
