<template src="./templates/index.html"></template>

<script>
/*
Слоты:
    * Слот по-умолчанию - элементы, которые открываются/прячутся по клику.
    * button - слот с кнопкой.


Пример использования:
```
<DropdownButton
    label="Добавить действие"
    :attributes="{
        'class': ['btn-blue'],
    }"
>
    <DropdownItem @click="doSomeStuff">Ответить на вопрос</DropdownItem>
    <DropdownItem>Сохранить ответ</DropdownItem>
    <DropdownItem>Новый вес вопроса</DropdownItem>
</DropdownButton>
```
 */

import FormButton from '@f/Button';

export default {
    name: 'DropdownButton',
    components: {
        FormButton,
    },
    props: {
        // Состояние открыт/закрыт можно задавать и отслеживать через v-model
        modelValue: {
            type: Boolean,
            default: false,
        },
        // Открыт ли dropdown по-умолчанию
        defaultOpened: {
            type: Boolean,
            default: false,
        },
        // Надпись на кнопке
        label: {
            type: String,
            default: '',
        },
        // Аттрибуты для кнопки, см. компонент FormButton
        attributes: {
            type: Object,
            default() {
                return {};
            },
        },
        // Заблокировать кнопку
        disabled: {
            type: Boolean,
            default: false,
        },
        // Состояние "загрузки" для кнопки (отображается спиннер)
        loading: {
            type: Boolean,
            default: false,
        },
        // Закрывать меню выбора автоматически при клике внутри области выбора.
        // Если установить в false, то управлять видимостью нужно через пропс modelValue
        autoClose: {
            type: Boolean,
            default: true,
        },
        // Не закрывать меню выбора при клике вне области элемента
        persistent: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    data() {
        return {
            showing: this.modelValue || this.defaultOpened,
            contentTop: 0,
        };
    },
    computed: {
        buttonLabel() {
            let icon = 'chevron chevron_rotate-down';
            if (this.showing) {
                icon = 'chevron chevron_rotate-top';
            }
            const iconTag = `<span class='icon ${icon} ml-1 mr-0'></span>`;

            return `${this.label}${iconTag}`;
        },
    },
    watch: {
        modelValue() {
            this.showing = this.modelValue;
        },
        showing() {
            this.$emit('update:modelValue', this.showing);

            if (this.showing) {
                this.$nextTick(() => {
                    this.updateContentTopPosition();
                });
            }
        }
    },
    methods: {
        // Метод для открытия дропдауна по $ref ссылке
        show() {
            this.showing = true;
        },
        // Метод для закрытия дропдауна по $ref ссылке
        hide() {
            this.showing = false;
        },
        toggle() {
            if (!this.disabled) {
                this.showing = !this.showing;
            }
        },
        // Закрытие при клике вне элемента, или при смене фокуса
        onFocusOut(event) {
            if (event.currentTarget.contains(event.relatedTarget)) {
                // фокус сместился на дочерний элемент - ничего не делаем
                return;
            }
            if (!this.persistent) {
                this.hide();
            }
        },
        onContentClick() {
            if (this.autoClose) {
                this.hide();
            }
        },
        // Если контент не помещается на экран под кнопкой, то отображаем его сверху, над кнопкой
        updateContentTopPosition() {
            if (!this.$refs.button || !this.$refs.content) {
                return;
            }
            const margin = 2; // отступ контента от кнопки
            const buttonRect = this.$refs.button.getBoundingClientRect(); // размеры блока с кнопкой
            const contentRect = this.$refs.content.getBoundingClientRect(); // размеры блока с контентом
            const {clientHeight} = document.documentElement; //  внутренняя высота окна без полос прокрутки

            const dropdownBottom = buttonRect.bottom + contentRect.height + margin;
            if (dropdownBottom > clientHeight) {
                this.contentTop = -(contentRect.height + margin);
            } else {
                this.contentTop = buttonRect.height + margin;
            }
        },
    }
};
</script>

<style scoped lang="scss" src="./styles/dropdown.scss"></style>
